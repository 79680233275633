body {
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
  margin: 0;
  background-color: rgb(236 236 236);
}
.flex-grow {
  flex-grow: 1;
}
.flexSp{
  display: flex;
  justify-content: center;

}
#root{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.scroll-button {
  height: 110px;
  align-self: center;
  font-size: 40px;
  padding: 0 12px;
  border: 0;
  position: absolute;
  color: rgb(86, 86, 86);
  z-index: 2;
  background-color: rgb(236 236 236);
  border-radius: 10px 0 0 10px;
  transition: color 0.3s ease;
}

.scroll-button:hover {
  color: black;
}


@media (max-width: 1050px) {
  .HeightOW {
    height: auto !important;
  }
  #LoginMain {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .LoginImg {
    max-height: 90% !important;
  }
  .checkout-100 {
    width: 100% !important;
  }
  .TotalPriceSubDiv1 {
    margin: 30px 0 !important;
  }
  .CartDiv {
    display: none;
  }
  .CartItems {
    margin-bottom: 7px;
    margin-top: 7px;
  }
  .CartProceed {
    margin: 34px 32px 16px 32px !important;
  }
  .PCCart {
    display: none;
  }
  .CartMainn {
    margin-top: 36px;
  }
  .col-6 {
    width: 100% !important;
  }
  .TotalPriceSubDiv {
    margin: 0 !important;
    margin-top: 28px !important;
  }

  .FeaturedDiv {
    margin-left: 16px !important;
  }
  .MobileCart {
    display: flex !important;
  }
  .NavBarMain {
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }
  #ItemDetails {
    margin-top: 26px;
    margin-bottom: 26px;
  }
  .IVMob {
    width: 100% !important;
    height: auto !important;
  }
  .NavBarMain h1 {
    margin-bottom: 10px;
  }

  nav {
    margin: 0;
    padding: 0 !important;
  }

  nav ul {
    flex-direction: column;
    align-self: center;
    align-items: center;
  }

  nav li {
    margin: 10px 0;
  }

  .NavbarButtons {
    text-decoration: none;
    margin-top: 10px;
    margin-left: 0;
  }
  .OrderNext{
    margin: 12px 0 0 0!important;
  }
  .OrderBoxSub{
    flex-direction: column;
  }
}

.TopSaleBar {
  text-align: center;
  background-color: black;
  color: white;
  font-size: 12px;
  padding: 10px;
}

.SepLine {
  height: 1px;
  background: rgb(160, 160, 160);
}

.NavBarContainer {
  display: flex;
  justify-content: center;
}

.TotalPriceSubDiv {
  margin: 0 60px;
  border: 1px gray solid;
  border-radius: 4px;
}
.TotalPriceSubDiv1 {
  margin: 0 60px;
  border-radius: 4px;
}
.NavBarMain {
  background-color: white;
  padding-left: 60px !important;
  padding-right: 30px !important;
  padding-top: 25px !important;
  z-index: 1000;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.NavBarMain h1 {
  font-size: 26px;
  font-family: "Poppins", sans-serif;
  margin: 0;
}
nav {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.NavbarButtons {
  color: black;
  transition: color 0.3s ease; /* Adding transition for smooth color change */
  border-radius: 0px;
  border: transparent;
}

.NavbarButtons:hover {
  color: #db4444;
  cursor: pointer;
}

nav ul {
  list-style-type: none;
  font-size: 14px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

nav li {
  margin-left: 15px;
  margin-right: 40px;
}

nav li a {
  text-decoration: none;
  color: #000;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.row-ow {
  margin: 0;
  padding: 0;
}

nav li a:hover {
  cursor: pointer;
}

nav .selected a {
  position: relative;
}

.LoginImg {
  max-width: 80%;
}
nav .selected a::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}
.FeaturedDiv {
  margin: 46px 16px 0 60px;
}
.rectRed {
  display: block;
  background-color: #db4444;
  height: 30px;
  width: 14px;
  border-radius: 4px;
}
.FeaturedDiv rect {
  display: block;
  background-color: #db4444;
  height: 30px;
  width: 14px;
  border-radius: 4px;
}
.FeaturedDivText {
  align-self: center;
  margin: 0px 0px 0px 10px;
  color: #db4444;
}
.FeaturedDivSubText {
  font-size: Bold;
  font-size: 20px;
  margin-top: 12px;
  margin-bottom: 18px;
}
.product-card {
  border-radius: 12px;
  background: white;
  display: inline-block;
  width: 200px;
  margin: 10px;
  position: relative;
}

.product-info {
  padding: 10px;
  font-size: 14px;
}
.product-info h3 {
  font-size: 18px;
}
.product-card img {
  display: block;
  margin: 0 auto;
  max-height: 150px;
  max-width: 200px;
}
.loading-placeholder {
  background-color: gray; /* Grayish background color */
  height: 150px;
  width: 200px;
  border-radius: 8px; /* Optional: Add rounded corners for a better visual */
}

.add-to-cart {
  display: block;
  padding: 4px 0;
  background-color: transparent;
  color: white;
  font-size: 12px;
  border: none;
  width: 200px;
  cursor: pointer;
}

.product-card:hover .add-to-cart {
  background-color: black;
}

.ActualPrice {
  color: gray;
}

.SellingPrice {
  color: #db4444;
  margin-left: 6px;
}

.DiscountDiv {
  color: white;
  background-color: #db4444;
  position: absolute;
  margin: 10px 0 0 10px;
  padding: 3px 8px;
  border-radius: 8px;
}
#root {
  height: 100vh;
}
.InfoBtnDiv {
  color: black;
  background-color: transparent;
  position: absolute;
  align-content: center;
  padding: 3px 8px;
  right: 10px;
  top: 8px;
  cursor: pointer;
  height: 32px;
  border: 0;
  width: 40px;
  border-radius: 8px;
}

.InfoBtnDiv:hover {
  color: #db4444;
}

.button-category {
  border: 1px solid rgb(134, 134, 134) !important;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 158px !important;
  min-width: 158px !important;
  height: 145px;
  background-color: white;
  color: black;
  border-radius: 10px;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.button-category .material-symbols-outlined {
  font-size: 48px;
  margin-right: 10px;
  margin: 0;
  margin-bottom: 10px;
}
.ItemList {
  margin-right: 10px;
}
.button-category:hover {
  background-color: #db4444;
  color: white;
}
.authMain {
  font-size: 36px;
}

.authSub {
  font-size: 16px;
  margin-top: 18px;
  margin-bottom: 18px;
}

.authInput {
  background-color: white;
  border: 0 !important;
  width: 100%;
  font-size: 16px;
  line-height: 32px;
  border-bottom: 1px solid rgb(134, 134, 134) !important;
  margin-top: 24px;
}

.authInput:focus {
  outline: none;
}

.authButton {
  background: #db4444;
  color: white;
  width: 100%;
  border: 0;
  padding: 12px 0;
  border-radius: 6px;
  font-size: 16px;
}

.authButton:hover {
  background: #d80707;
}

.Collapsed {
  display: none !important;
}

.authAhref {
  color: 16px;
  color: black;
  margin-left: 12px;
}

.ItemName {
  font-size: 24px;
  margin-top: 20px;
}

.Stock {
  color: #00ff66;
  font-size: 14px;
  margin-top: 16px;
}

.ItemDescription {
  font-size: 14px;
  margin-top: 16px;
}

.PriceDiv {
  font-size: 24px;
  margin-top: 4px;
  color: gray;
}

.DiscountedPrice {
  margin-left: 12px;
  color: #db4444;
}

.DiscountItemDiv {
  color: white;
  background-color: #db4444;
  margin-top: 16px;
  display: inline-block;
  max-height: 30px;
  padding: 3px 8px;
  border-radius: 8px;
}

.AddCardtDiv {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px gray solid;
  margin-top: 14px;
  color: black;
  border-radius: 3px;
}

.AddCartPageBtn {
  justify-content: center;
  align-items: center;
  display: flex;
  border: 0;
  border-radius: 2px 0 0 2px;
  height: 38px;
  transition: background-color 0.3s ease;
}
.LabelPageInfroAC {
  font-size: 20px;
  padding: 0px 18px;
}

.bgw {
  border-right: 1px gray solid;
}
.bgw:hover {
  background: rgb(186, 186, 186);
}
.bgdb:hover {
  background: #d80707;
}
.bgdb1:hover {
  background: #d80707;
}

.BuyNow {
  background: #db4444;
  height: 38px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  margin-top: 14px;
  padding: 0 30px;
  border-radius: 4px;
  margin-left: 16px;
  transition: background-color 0.3s ease;
}

.BuyNow:hover {
  background: #d80707;
}

#ItemDetails {
  overflow: auto;
  align-content: center;
  justify-content: left;
  align-items: center;
  display: flex;
  padding-top: 20px;
}

.CartDiv {
  margin: 40px 32px 16px 76px;
  border-bottom: 1px gray solid;
  padding-bottom: 12px;
}

.CartDiv1 {
  margin: 12px 32px 16px 76px;
  padding-bottom: 12px;
}
.CartHeadings {
  font-size: 16px;
}

.AddCardtDiv1 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px gray solid;
  margin-top: 14px;
  color: black;
  border-radius: 3px;
}

.AddCartPageBtn1 {
  justify-content: center;
  align-items: center;
  display: flex;
  border: 0;
  border-radius: 2px 0 0 2px;
  height: 28px;
  width: 32px;
  transition: background-color 0.3s ease;
}
.LabelPageInfroAC1 {
  font-size: 16px;
  padding: 0px 12px;
}

.bgdb {
  background: #db4444;
  border-radius: 0 2px 2px 0;
  color: white;
}
.bgdb1 {
  background: #db4444;
  border-radius: 0 2px 2px 0;
  color: white;
  padding: 0 30px;
}
.bgw {
  border-right: 1px gray solid;
}
.bgw:hover {
  background: rgb(186, 186, 186);
}

.CartItemDiv {
  border-bottom: 1px gray solid;
  padding-bottom: 12px;
  margin: 12px 32px 16px 76px;
}

.CartProceed {
  margin: 34px 32px 16px 76px;
}

.CheckOutRightDiv {
  margin-left: 12px;
}

.CouponButton {
  background: #db4444;
  border-radius: 4px;
  color: white;
  border: 0;
  padding: 4px 26px;
  transition: background-color 0.5s ease;
  height: 36px;
  text-wrap: nowrap;
}
.CouponButton:hover {
  background: #d80707;
}
.CartTotalTxt {
  margin: 20px 30px;
  font-size: 16px;
}
.TotalDivv {
  margin: 0 20px 10px 20px;
  font-size: 14px;
  padding-bottom: 10px;
  border-bottom: 1px gray solid;
}

.TotalDivv1 {
  margin: 0 0 10px 0;
  font-size: 14px;
  padding-bottom: 10px;
  border-bottom: 1px gray solid;
}

.form-m {
  margin-top: 10px;
}

.CheckoutButton {
  background: #db4444;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 2px;
  color: white;
  border: 0;
  padding: 4px 26px;
  transition: background-color 0.5s ease;
  height: 46px;
  margin-top: 8px;
  margin-bottom: 24px;
  text-wrap: nowrap;
}

.CheckoutButton:hover {
  background: #d80707;
}

.PlaceOrderButton {
  background: #db4444;
  border-radius: 3px;
  margin-top: 24px;
  color: white;
  border: 0;
  padding: 2px 26px;
  transition: background-color 0.5s ease;
  height: 46px;
  margin-bottom: 24px;
  text-wrap: nowrap;
}

.PlaceOrderButton:hover {
  background: #d80707;
}

.addInput {
  border: gray 1px solid;
  font-size: 14px;
}

.CheckOutDiv {
  margin: 40px 30px 0 30px;
}

.CheckOutDivHeader {
  font-size: 24px;
}

.inputs {
  margin-top: 26px;
}

.form-ow {
  margin-bottom: 18px;
  font-size: 16px;
}

.notification-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4caf50;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 255, 13, 1.2);
  animation: slideInUp 0.5s ease-out forwards;
}

.NotifError {
  background-color: #d80707 !important;
  box-shadow: 0 0 10px rgba(216, 7, 7, 1.2);
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.notification-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.notification-description {
  font-size: 14px;
}

.CartQuantiy {
  background: black;
  color: white;
  display: inline-block;
  padding: 0px 8px;
  border-radius: 16px;
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: -15px;
  margin-top: -8px;
}

.OrderBoxMain {
  border: 1px rgb(160, 160, 160) solid;
  border-radius: 10px;
  margin-bottom: 18px;
  background-color: white;
}

.OrderBoxSub {
  padding: 12px 26px;
  display: flex;
  border-radius: 10px 10px 0 0;
  background: white;
  border-bottom: 1px rgb(160, 160, 160) solid;
}

.OrderPlaced {
  font-size: 14px;
  margin-bottom: 2px;
  color: rgb(83, 83, 83);
}

.OrderPlacedDate {
  font-size: 16px;
}

.OrderNext {
  margin-left: 40px;
}

.OrderDetailsMain {
  padding: 14px 26px;
}

.OrderDetailsStatus {
  font-weight: bold;
  font-size: 18px;
}

.OrderImg {
  max-width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.OrderDetailss {
  width: 100%;
}

.OrderDetailsName {
  font-size: 16px;
  color: #007185;
  text-decoration: none;
}
.OrderDetailsName:hover {
  text-decoration: underline;
}

.PaymentStatus {
  font-size: 14px;
  color: #007600;
  margin-bottom: 14px;
}

.OrderBoxButtons {
  width: 100%;
  border: 1px rgb(169, 169, 169) solid;
  margin-top: 5px;
  display: block;
  text-decoration: none;
  margin-bottom: 5px;
  color: black;
  text-align: center;
  padding: 2px;
  background: transparent;
  border-radius: 6px;
  transition: all 0.6s ease;
}

.OrderBoxButtons:hover {
  background-color: white;
  border: 1px #db4444 solid;
}
.SellerName {
  font-size: 12px;
  margin-top: 2px;
  color: rgb(70, 70, 70);
}

.OrderPageDiv {
  padding: 18px;
}

.AccountInfoDiv {
  padding: 18px;
}

.AcccontInfoSubDiv {
  margin-bottom: 16px;
  background: white;
  border: 1px rgb(169, 169, 169) solid;
  padding: 16px 18px;
  border-radius: 6px;
}

.EditButton {
  background: white;
  border: 1px rgb(169, 169, 169) solid;
  padding: 2px 16px;
  border-radius: 6px;
  margin-left: auto;
  margin-right: 0;
  transition: all 0.6s ease;
}

.EditButton:hover {
  background: rgb(228, 228, 228);
}

.AccountInfoEdit {
  background: white;
  display: inline-block;
}
.footer {
  background-color: black;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding-inline: 20px;
  text-align: center;
}

.footer-text {
  font-size: 1rem;
  margin: 6px 0px;
}

.social-icons {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
}

.icon {
  height: 1.25rem;
}

@media (max-width: 1050px) {
  nav li{
    margin: 8px 0!important;
  }
  .NavbarButtons{
    margin-bottom: 8px;
    margin-left: 0!important;
  }
}